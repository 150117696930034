import { graphql } from 'gatsby';
import { CardsList } from 'libs/brand-system/src/templates/CardsList';
import { MediaShareLinks } from 'libs/growth-platform-brand-system-v2/src/components/MediaShareLinks';
import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import { container } from 'libs/growth-platform-brand-system-v2/src/styles/themes';
import { HeroBlog } from 'libs/growth-platform-brand-system-v2/src/templates/HeroBlog';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import React, { useRef } from 'react';
import ConversionBannerComponent from '../../components/BlockComponents/ConversionBannerComponent';
import { TemplateArticleZone } from '../../components/TemplateArticleZone';
import Layout from '../../layout';
import {
  HeadWrapper,
  largestContentfullPaintImg,
} from '../../layout/HeadWrapper';
import Subheader from '../../layout/subheader';
import { schemaHeroBlog } from '../../morphismSchema/templates/schemaHeroBlog';
import { getLastAticlesCards } from '../../utils/getLastAticlesCards';
import { Subpath, linkResolver } from '../../utils/linkResolver';
import { useCountWords } from '../../utils/useCountWords';

export const Head = ({ data, pageContext, location }: any) => {
  const heroProps = data?.datoCmsBlogArticle?.hero?.[0];
  const largestContentfullPaintImg: largestContentfullPaintImg =
    heroProps?.image?.gatsbyImageData?.images?.fallback || null;
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="BlogArticle"
      dataName={'datoCmsBlogArticle'}
      largestContentfullPaintImg={largestContentfullPaintImg}
    />
  );
};

export const query = graphql`
  query DatoBlogArticle($locale: String, $id: String) {
    datoCmsBlogArticle(locale: $locale, id: { eq: $id }) {
      ...BlogArticle
    }
    allDatoCmsBlogArticle(
      limit: 6
      locale: $locale
      filter: { locales: { eq: $locale } }
      sort: { _allDateLocales: { value: DESC } }
    ) {
      nodes {
        id
        slug
        date
        hero {
          ... on DatoCmsHeroBlog {
            image {
              url
              alt
              gatsbyImageData
              width
              height
            }
          }
        }
        metaFields {
          description
          title
        }
      }
    }
    datoCmsBlogHome(locale: $locale) {
      slug
      trackingPageName {
        title
      }
      header {
        ...CustomMenu
      }
    }
  }
`;

const wordsReadPerMinute = 200;

const BlogArticle = ({ data, pageContext }: any) => {
  const screenSize = useScreenSize();
  const doc: any = data.datoCmsBlogArticle;
  const lastArticles = data.allDatoCmsBlogArticle?.nodes;
  const homepage = data.datoCmsBlogHome;
  const hero = doc?.hero?.[0];
  const stickyBanner = doc?.stickyBanner?.[0];
  const isDesktop = !['xs', 'sm', 'md'].includes(screenSize);
  const useCountWordsRef = useRef<any>(null);
  const nbWords = useCountWords(useCountWordsRef);
  const minutes = Math.floor(nbWords / wordsReadPerMinute);
  const seconds = (nbWords / wordsReadPerMinute - minutes) * 0.6;

  // generate tracking page name from document.title
  doc.trackingPageName = {
    title: doc?.metaFields?.title,
  };

  const lastArticlesCards = getLastAticlesCards({
    lastArticles,
    screenSize,
    locale: pageContext?.locale,
    isBlogArticle: true,
  });

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      customHeader={homepage?.header}
      templateName="BlogArticle"
    >
      <div className={classNames(container)}>
        <Subheader
          doc={doc}
          hero={hero}
          slugBase={homepage?.slug}
          pageContext={pageContext}
        />
        <HeroBlog
          {...(morphism(schemaHeroBlog, {
            ...hero,
            stickyBanner: stickyBanner,
            locale: pageContext.locale,
            screenSize,
          }) as any)}
          updateTime={new Date(doc?.date)}
          readingTime={minutes * 60 + seconds}
          locale={pageContext?.locale}
          className="mx-24 md:mx-auto"
        />
      </div>
      <div ref={useCountWordsRef}>
        <TemplateArticleZone doc={doc} locale={pageContext.locale} />
      </div>
      {!isDesktop && (
        <div className="flex justify-center pb-40">
          <MediaShareLinks
            url={`https://payfit.com${linkResolver(
              doc,
              pageContext?.locale,
              '',
              Subpath.BlogArticles,
            )}`}
            locale={pageContext.locale}
          />
        </div>
      )}
      {doc.conversionBanner.length > 0 && (
        <ConversionBannerComponent
          block={doc.conversionBanner[0]}
          locale={pageContext?.locale}
          pageId={0}
          classname={classNames('z-0 max-w-[1202px] mb-64', container)}
        ></ConversionBannerComponent>
      )}
      {lastArticles.length > 0 && (
        <Section backgroundColor="blue">
          <CardsList
            isBlogArticle
            id={'last-articles'}
            title={doc?.wordingAboveOtherArticleList}
            cards={lastArticlesCards}
            backgroundColor="blue"
            shouldDisplay={isDesktop ? 6 : 3}
            seeMoreBatchSize={isDesktop ? 6 : 3}
            seeMoreButtonLabel={'Voir plus'}
            buttonType="secondary"
          />
        </Section>
      )}
    </Layout>
  );
};

export default BlogArticle;
